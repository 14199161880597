<template>
  <div class="apps">
    <div class="apps-head">
      <Card color="primary"><h3>приложения</h3></Card>
      <Card color="grey-dark"></Card>
    </div>
    <div class="apps-body">
      <div class="apps-body__column">
        <Card class="apps-body__card" color="grey-dark">
          <div class="apps-body__section">
            <div class="apps-body__pic">
              <img
                src="@/assets/apps/APPSELLER.svg"
                alt=""
                width="124"
                height="124"
              />
            </div>
            <div class="apps-body__content">
              <h3>APPSELLER</h3>
              <p>Приложение для продавца</p>
            </div>
            <div class="apps-body__desc">
              В приложении можно отслеживать статистику своих продаж, узнавать
              новости и получать подсказки по тарифам.
            </div>
          </div>
          <div class="apps-body__section">
            <div class="apps-body__desc">
              Быстро и просто оформить подключение клиента со своего смартфона
              вам позволит приложение AppSeller.
            </div>
            <div class="apps-body__link">
              <QRLink
                src="/static/demo/qr-app-2.png"
                link="http://onelink.to/twcjcq"
              />
            </div>
          </div>
        </Card>
        <Collapse color="grey-dark">
          <template #head>Преимущества AppSeller</template>
          <template #body>
            <ul>
              <li>
                при оформлении договора не нужно использовать бумажные РФА,
                хранить их на торговой точке и передавать представителям t2;
              </li>
              <li>простые и последовательные шаги при оформлении договора;</li>
              <li>процесс занимает всего несколько минут;</li>
              <li>
                безопасность личных данных: данные передаются напрямую в t2;
              </li>
              <li>
                возможность решения возникающих вопросов на горячей линии;
              </li>
              <li>
                автоматическое зачисление дилер-бонуса, не нужно набирать
                дополнительные команды.
              </li>
              <li>
                Возможность оформить заявку на перенос номера клиента в сеть t2.
              </li>
            </ul>
          </template>
        </Collapse>
      </div>
      <div class="apps-body__column">
        <Card class="apps-body__card" color="grey-dark">
          <div class="apps-body__section">
            <div class="apps-body__pic">
              <img src="@/assets/apps/LK.svg" alt="" width="124" height="124" />
            </div>
            <div class="apps-body__content">
              <h3>Личный кабинет <span>t2</span></h3>
              <p>Приложение для клиента</p>
            </div>
          </div>
          <div class="apps-body__section">
            <div class="apps-body__link">
              <QRLink
                src="/static/demo/qr-app-1.svg"
                link="https://onelink.to/ts5aby"
              />
            </div>
          </div>
        </Card>
        <Collapse color="grey-dark">
          <template #head
            >Лучший способ управлять услугами и счетом для абонентов
            t2</template
          >
          <template #body>
            <ul>
              <li>подключение к t2 с саморегистрацией;</li>
              <li>подключение eSIM;</li>
              <li>проверка остатков гигабайтов и минут;</li>
              <li>настройка тарифа;</li>
              <li>подключение или отключение дополнительных услуг;</li>
              <li>возможность делиться гигабайтами с абонентами t2;</li>
              <li>возможность продавать и покупать минуты и ГБ;</li>
              <li>
                удобный виджет для отслеживания остатков минут, гигабайтов и
                баланса.
              </li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Управление счетом</template>
          <template #body>
            <ul>
              <li>
                пополнение баланса с помощью бесконтактных платежей или
                банковских карт;
              </li>
              <li>установка платежа с привязанной банковской карты;</li>
              <li>использование обещанного платежа;</li>
              <li>просмотр статистики расходов и заказа детализации;</li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Бонусы</template>
          <template #body>
            <ul>
              <li>
                скидки, кешбэки и подарки от наших партнеров по программе
                «Больше»;
              </li>
              <li>
                уникальные предложения и приглашения на интересные мероприятия.
              </li>
            </ul>
          </template>
        </Collapse>
      </div>
    </div>
    <div class="apps-list">
      <div class="apps-list__head">
        <h3>Шаги по активации приложения Appseller</h3>
      </div>
      <div class="apps-list__body">
        <Collapse color="white">
          <template #head>Регистрация в приложении</template>
          <template #body>
            <ContentSwiper>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/1.webp')]"
                  number="1"
                  text="Зайдите в мобильное приложение. Откроется экран загрузки."
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/2.webp')]"
                  number="2"
                  text="Введите номер, с которым хотите зарегистрироваться в приложении. Нажмите «Далее»."
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/3.webp')]"
                  number="3"
                  text="Введите код из SMS."
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/4.webp')]"
                  number="4"
                  text="Заполните анкету продавца
                      <ul><li>Фамилия, имя, отчество</li><li>Код точки продаж;</li><li>Контактный номер телефона;</li><li>E-mail (не обязательное поле)</li><li>Приложите своё фото по желанию</li><li>Подпишите анкету. Распишитесь пальцем внутри рамки. В дальнейшем она будет добавляться при подписании документов.</li></ul>"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/5.webp')]"
                  number="5"
                  text="Сделайте селфи с паспортом"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/1/6.webp')]"
                  number="6"
                  text="При нажатии на кнопку «Отправить анкету» отобразиться экран, сообщающий об успешной регистрации."
                />
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
        <Collapse color="white">
          <template #head>Что еще может t2 AppSeller</template>
          <template #body>
            <ContentSwiper>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/2/1.webp')]"
                  number="1"
                  title="НОВОСТИ:"
                  text="Все новости t2 можно узнать прямо в AppSeller. Новости представлены в виде ленты с фильтрами и возможностью поставить «лайк» самым полезным статьям."
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/2/2.webp')]"
                  number="2"
                  title="СТАТИСТИКА ПРОДАЖ:"
                  text="Нажмите на кнопку «Вся история» на главной странице и вы увидите статистику продаж по дням с возможностью гибко фильтровать список."
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/2/3.webp')]"
                  number="3"
                  title="ПОРТАЛ ОБУЧЕНИЯ:"
                  text="<p>Портал обучения АВС находится во вкладке «Развитие».</p>
                  <p>На нем вы найдете мотивационные программы и обучающие курсы.</p>"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  :img="[require('@/assets/apps/2/4.webp')]"
                  number="4"
                  title="ПОРТАЛ ОБУЧЕНИЯ:"
                  text="При первом входе на портал обучения необходимо ввести логин и пароль от учетной записи, если она есть. Либо зарегистрироваться, заполнив небольшую анкету. После проверки вашей заявки, вы получите email c подтверждением регистрации"
                />
              </swiper-slide>
              <swiper-slide>
                <ContentStepItem
                  number="5"
                  text="<p>Есть вопросы по работе приложения? Задавайте их в чате оператору в разделе «Поддержка».</p>
                  <p>Функционал приложения будет расширяться, и вы сможете работать с покупателем еще качественней и быстрее. Ждите новостей в t2 AppSeller!</p>"
                />
              </swiper-slide>
            </ContentSwiper>
          </template>
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script>
import QRLink from "./QRLink.vue";

import { Navigation, Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/vue";
import Card from "./Card.vue";
import Collapse from "./Collapse.vue";
import ContentStepItem from "./ContentStepItem.vue";
import ContentSwiper from "./ContentSwiper.vue";

export default {
  components: {
    QRLink,
    SwiperSlide,
    ContentStepItem,
    Card,
    Collapse,
    ContentSwiper,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style lang="scss" scoped>
.apps {
  padding-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &-head {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: 345px 1fr;
      gap: var(--widthGapV);
    }
    &:deep(:nth-child(1)) {
      height: 80px;
      @include md {
        height: 190px;
      }
    }
    &:deep(:nth-child(2)) {
      height: 114px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url(@/assets/apps/promo/m.webp);
      @include md {
        height: 190px;
        background-image: url(@/assets/apps/promo/d.webp);
      }
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
    align-items: start;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--widthGapV);
      &:deep(.card) {
        border-radius: 18px;
      }
    }
    &__column {
      display: grid;
      gap: 8px;
    }
    &__card {
      display: grid;
      gap: var(--widthGapV);
      @include md {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__section {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 18px;
      @include md {
        grid-auto-flow: row;
        grid-template-columns: auto;
        align-content: start;
      }
    }
    &__content {
      display: grid;
      align-content: start;
      gap: 8px;
    }
    &__pic {
      img {
        display: block;
      }
    }
    &__desc {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
    &__link {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
  }

  &-list {
    display: grid;
    gap: var(--widthGapH);
    &__head {
    }
    &__body {
      display: grid;
      gap: var(--widthGutter);
    }
    &:deep(.collapse) {
      @include md {
        border-radius: var(--radiusOuter);
      }
    }
  }
  &-swiper {
    position: relative;
    display: grid;
    max-width: 1004px;
    margin: 0 calc(var(--widthPadding) * -1);
    @include md {
      margin: 0;
    }
    &:deep(.swiper) {
      width: 100%;
    }
    &:deep(.swiper-custom-navigation) {
      @include md {
        position: relative;
        filter: invert(1);
        margin: 24px auto 0;
      }
    }
    &:deep(.swiper-custom-pagination) {
      @include md {
        display: none;
      }
    }
  }
}
</style>
